<template>
  <div>
    <h2 class="mb-4">Список подборок сравнений</h2>

    <data-table ref="dataTable" url="compare-compilations" :headers="headers">
      <template v-slot:[`item.actions`]="{ item: compilation }">
        <nobr>
          <v-btn
            class="mr-2"
            icon
            color="accent"
            :to="{ name: 'compare-compilations-id', params: { id: compilation.id } }"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>

          <v-btn icon color="error" @click="deleteCompilation(compilation)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </nobr>
      </template>
    </data-table>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

export default {
  data() {
    return {
      headers: [
        { text: "Название", value: "name" },
        { text: "Акции", value: "tickers" },
        { text: "Slug", value: "slug" },
        { text: "Действия", value: "actions", sortable: false }
      ]
    };
  },
  methods: {
    async deleteCompilation({ id }) {
      try {
        await this.$axios.$delete(`compare-compilations/${id}`);
        await this.$refs.dataTable.update();

        this.$snackbar.success("Удалено");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не удалено" + html);
      }
    }
  }
};
</script>
